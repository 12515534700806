import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"

const WeddingPartyPage = () => (
  <Layout title="Wedding Party">
    <Container pad>
      <br />
      I'm workin' on it okay???
      <br />- Rick
    </Container>
  </Layout>
)

export default WeddingPartyPage
