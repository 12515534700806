import styled from "styled-components"

const Container = styled.div`
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  ${({ pad }) => pad && "padding: 1rem;"};
`

export default Container
