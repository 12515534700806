import styled from "styled-components"

const LineBehind = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 320px;
  text-align: center;

  @media (max-width: 420px) {
    max-width: 60vw;
  }

  &:before,
  &:after {
    content: "";
    background-color: #211e1f;
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  &:before {
    right: 1em;
    margin-left: -50%;
  }
  &:after {
    left: 1em;
    margin-right: -50%;
  }
`

export default LineBehind
