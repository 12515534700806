import React from "react"
import styled from "styled-components"

const CompactName = styled.span`
  font-size: 24px;
  letter-spacing: -4px;
`

const Small = styled.span`
  font-size: 12px;
  vertical-align: middle;
`

const Reverse = styled.span`
  transform: scale(-1, 1);
  display: inline-block;
  margin-left: 3px;
`

const Logo = () => (
  <CompactName>
    K <Small>&hearts;</Small> <Reverse>R</Reverse>
  </CompactName>
)

export default Logo
