import React from "react"
import SEO from "../components/Seo"
import Nav from "../components/Nav"
import Header from "../components/Header"
import Footer from "../components/Footer"
import styled from "styled-components"

const Main = styled.main``

const Layout = ({ children, title, compact = true, renderHeader }) => (
  <>
    <SEO title={title} />
    {renderHeader ? (
      renderHeader({ compact })
    ) : (
      <>
        <Header compact={compact} />
        <Nav />
      </>
    )}
    <Main>{children}</Main>
    <Footer />
  </>
)

export default Layout
