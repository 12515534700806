import React from "react"
import styled from "styled-components"
import LineBehind from "../components/LineBehind"
import Logo from "../components/Logo"

const Title = styled.div`
  font-size: 38px;
  text-transform: uppercase;
  letter-spacing: 6px;
  padding-top: 3rem;
  padding-bottom: 5rem;
`

const SmallScreenBreak = styled.br`
  @media (min-width: 1000px) {
    display: none;
  }
`

const LargeScreenSpan = styled.span`
  @media (max-width: 1000px) {
    display: none;
  }
`

const LargeScreenSpace = () => <LargeScreenSpan> </LargeScreenSpan>

const NameSeparator = styled(LineBehind)`
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const PaddedHeader = styled.header`
  padding: 1rem;
  text-align: center;
`

const Header = ({ compact, ...props }) =>
  compact ? (
    <PaddedHeader {...props}>
      <Logo />
    </PaddedHeader>
  ) : (
    <PaddedHeader {...props}>
      <Title>
        Katrina
        <SmallScreenBreak />
        <LargeScreenSpace />
        Combs
        <NameSeparator>and</NameSeparator>
        Rick
        <SmallScreenBreak />
        <LargeScreenSpace />
        Sullivan
      </Title>
    </PaddedHeader>
  )

export default Header
