import React from "react"
import styled from "styled-components"
import Nav from "./Nav"
import Container from "./Container"
import LineBehind from "../components/LineBehind"

const FooterContainer = styled(Container)`
  padding-bottom: 3rem;
`

const FadedLine = styled(LineBehind)`
  font-size: 16px;
  margin-top: 3rem;
  margin-bottom: 3rem;
`

const Footer = () => (
  <footer>
    <FooterContainer>
      <FadedLine>K &hearts; R</FadedLine>
      <Nav />
    </FooterContainer>
  </footer>
)

export default Footer
