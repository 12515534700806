import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Container from "./Container"

const NavContainer = styled(Container)`
  display: flex;
  letter-spacing: 2px;
  justify-content: space-evenly;
  text-transform: uppercase;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      margin-bottom: 5px;
      width: 50%;
      &:nth-child(even) {
        text-align: right;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom-color: inherit;
    }
  }
`

const activeStyle = { borderBottomColor: "inherit" }

const NavLink = props => (
  <div>
    <Link activeStyle={activeStyle} {...props} />
  </div>
)

const Nav = props => (
  <nav {...props}>
    <NavContainer pad>
      <NavLink to="/">Home</NavLink>
      <NavLink to="/photos">Photos</NavLink>
      <NavLink to="/events">Events</NavLink>
      <NavLink to="/registry">Registry</NavLink>
    </NavContainer>
  </nav>
)

export default Nav
